*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--grey);
}

html, body{
  font-family: amperzand, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  overflow: overlay;
  min-height: 100vh;
  background-color: black;
  color: white;
}

@font-face {
  font-family: amperzand;
  src: url(./fonts/Amperzand.ttf);
}

.row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.col{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
} 
